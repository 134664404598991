/**
 * Application entry point.
 *
 * This file includes `import`'s for all the components/directives used
 * by the HTML page and the controller to provide the configuration.
 */

import './sass/desktop.scss';
import './sass/vars_desktop.scss';

import angular from 'angular';

import {isEventUsingCtrlKey} from 'ngeo/utils';
import gmfControllersAbstractDesktopController, {
  AbstractDesktopController,
} from 'gmf/controllers/AbstractDesktopController';
import config from 'gmf/controllers/AbstractAppController.js';
import ngeoRoutingModule from 'ngeo/routing/module';

// Custom draw line-polygon. Redefine some ngeo src/draw/Controller.js and
// ngeo contribs/gmf/src/drawing/drawFeatureComponent.html things.
import grDrawLinePolygon from '../linepolygon/draw/load_and_override.js';
import gr_mapfish3GeofunctionsComponent from '../geofunctions/component.js';
import gr_mapfish3Base from '../gr_mapfish3module.js';

/**
 * @private
 */
class Controller extends AbstractDesktopController {
  /**
   * @param {import('gmf/controllers/AbstractAppController.js').Config} config A part of the application
   *     config.
   * @param {angular.IScope} $scope Scope.
   * @param {angular.auto.IInjectorService} $injector Main injector.
   */
  constructor($scope, $injector) {
    super($scope, $injector);

    // handle icon change on geofunction tool button when selected geometry type change
    this.geofunctionsiconcallback = (value) => {
      $('.geofunctions-tool').removeClass('geofunctions-point');
      $('.geofunctions-tool').removeClass('geofunctions-line');
      $('.geofunctions-tool').removeClass('geofunctions-polygon');
      if (value) {
        $('.geofunctions-tool').addClass(`geofunctions-${value}`);
      }
    };

    // watch activation/deactivation of geofunction tool
    this.geofunctionactive = false;
    $scope.$watch(
      () => this.geofunctionsPanelActive,
      (newVal) => {
        this.geofunctionactive = newVal;
        $scope.$broadcast('geofunctionsPanelChange');
        if (!newVal) {
          this.geofunctionsiconcallback(null);
        }
      }
    );
  }

  onKeydown(event) {
    if (event && isEventUsingCtrlKey(event) && event.key === 'p') {
      this.printPanelActive = true;
      event.preventDefault();
    }
  }
}

/**
 * @hidden
 */
const gr_mapfish3Module = angular.module('Appdesktop', [
  gr_mapfish3Base.name,
  gmfControllersAbstractDesktopController.name,
  grDrawLinePolygon.name,
  gr_mapfish3GeofunctionsComponent.name,
  config.name,
]);

gr_mapfish3Module.value('gmfContextualdatacontentTemplateUrl', 'gmf/contextualdata');
gr_mapfish3Module.run(
  /**
   * @ngInject
   * @param {angular.ITemplateCacheService} $templateCache
   */
  ($templateCache) => {
    // @ts-ignore: webpack
    $templateCache.put('gmf/contextualdata', require('./contextualdata.html'));
  }
);

gr_mapfish3Module.controller('DesktopController', Controller);

gr_mapfish3Module.constant('obj', 'none');

export default gr_mapfish3Module;
